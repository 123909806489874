





















































































































































































































































































































































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { BookMuluText } from "@/utils/base";
import MainHeader from "@/components/main-header/main-header.vue";
import Jingdu from "@/components/biaozhu/jingdu.vue";
import SearchFilter from "@/components/biaozhu/search-filter.vue";
import {
  GetCategory,
  GetRenwuList,
  GetBiaozhuyuan,
  DeleteRenwu,
  SearchBook,
  GetBookZhangjie,
  Fenpei,
  EditFenlei,
} from "@/request/mark";
@Component({
  components: {
    MainHeader,
    Jingdu,
    SearchFilter,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private jingduId: any = "";
  private selectRenwu: any = {};
  private fenpeiText: any = "分配";
  private ifShowAddFenlei: any = false;
  private ifShowAddRenwu: any = false;
  private ifShowFenpei: any = false;
  private ifShowJindu: any = false;
  private data: any[] = [];
  private pageSetting: any = {};
  private fenleis: any = [];
  private biaozhuyuan: any = [];
  private fenpeiStatus: any = ["待分配", "已分配"];
  private biaozhuStatus: any = [
    "待标注",
    "标注中",
    "待审核",
    "已打回",
    "已通过",
  ];
  private fenleiboxData: any = [];
  private renwuData: any = {};
  private fenpeiData: any = {};
  private shumings: any = [];
  private zhangjies: any = [];
  private biaozhuyuans: any = [];
  private zhangjieChange(e: any) {
    this.renwuData["章节"] = e;
    this.$forceUpdate();
  }
  private changeSearch(d: any) {
    d.list_type = this.filters.list_type;
    this.filters = d;
    this.getList();
  }
  private getBookMuluText(item: any) {
    return BookMuluText(item);
  }
  private shumingFocus() {
    this.remoteShumingMethod("");
  }
  private remoteShumingMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    SearchBook(this, params).then((res: any) => {
      this.shumings = res;
    });
  }
  private selectBook(e: any) {
    const params: any = {
      book_id: e._id,
    };
    GetBookZhangjie(this, params).then((res: any) => {
      this.zhangjies = res;
      this.renwuData["章节"] = {};
    });
  }
  private remoteFenpeiMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuan = res;
    });
  }
  private openAddFenlei() {
    const params: any = {};
    GetCategory(this, params).then((res: any) => {
      this.fenleis = res;
      this.fenleiboxData = JSON.parse(JSON.stringify(this.fenleis));
      this.ifShowAddFenlei = true;
    });
  }
  private openjindu(item: any) {
    if (!this.getIsShowQuanxian("任务管理标注进度")) {
      return;
    }
    this.jingduId = item.id;
    this.ifShowJindu = true;
  }
  private addOneFenlei() {
    this.fenleiboxData.push("");
  }
  private delOneFenlei(i: any) {
    this.fenleiboxData.splice(i, 1);
  }
  private addFenlei() {
    let arr: any = [];
    this.fenleiboxData.forEach((ele: any) => {
      if (ele) {
        arr.push(ele);
      }
    });
    const params: any = {
      names: arr,
    };
    EditFenlei(this, params).then((res: any) => {
      this.ifShowAddFenlei = false;
      this.$message.success("新增任务分类成功！");
      this.getList();
    });
  }
  private openAddRenwu() {
    this.renwuData = {};
    this.ifShowAddRenwu = true;
  }
  private addRenwu() {
    if (!this.renwuData["原文库"]) {
      this.$message.warning("请选择原文库");
      return;
    }
    if (!this.renwuData["书名"]._id) {
      this.$message.warning("请选择书名");
      return;
    }
    if (!this.renwuData["章节"]._id) {
      this.$message.warning("请选择章节");
      return;
    }
    this.ifShowAddRenwu = false;
    this.$router.push({
      path: "/main/mark/guanli/compose",
      query: {
        data: JSON.stringify(this.renwuData),
      },
    });
  }
  private openfenpei(item: any, val: any) {
    if (!this.getIsShowQuanxian("任务管理分配")) {
      return;
    }
    this.selectRenwu = item;
    this.fenpeiText = val;
    this.fenpeiData = {};
    this.ifShowFenpei = true;
  }
  private fenpeisure() {
    if (!this.fenpeiData["标注员"]) {
      this.$message.warning("请选择需要分配的数据标注员");
      return;
    }
    const params: any = {
      id: this.selectRenwu.id,
      user_id: this.fenpeiData["标注员"],
    };
    Fenpei(this, params).then((res: any) => {
      this.$message.success(this.fenpeiText + "成功！");
      this.ifShowFenpei = false;
      this.getList();
    });
  }
  private delOne(item: any) {
    if (!this.getIsShowQuanxian("任务管理删除")) {
      return;
    }
    this.$confirm(
      "您确定要删除该标注任务么？删除后该标注任务相关的数据将全部清除。请慎重操作！",
      "删除",
      {
        customClass: "commonConfirm",
      }
    )
      .then(() => {
        const params: any = {
          data: {
            id: item.id,
          },
        };
        DeleteRenwu(this, params).then((res: any) => {
          this.$message.success("删除成功!");
          this.getList();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  private changeListType(type: any) {
    this.filters.list_type = type;
    localStorage.setItem("renwuguanliFilter", JSON.stringify(this.filters));
    this.$forceUpdate();
  }
  /**
   * @description 查看
   */
  private goRead(item: any) {
    if (!this.getIsShowQuanxian("任务管理查看")) {
      return;
    }
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: "/main/mark/biaozhu/detail",
      query: {
        id: item.id,
        kind: "任务管理",
        type: "read",
      },
    });
  }
  /**
   * @description 获取任务列表
   */
  getList() {
    const params: any = {
      params: {
        search: this.filters.search,
        分配状态: this.filters["分配状态"],
        标注状态: this.filters["标注状态"],
        category: this.filters["分类"],
        user_id: this.filters["标注员"],
        order_field: "创建时间",
        order_value: this.filters.sort_value,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
        kind: "任务管理",
      },
    };
    localStorage.setItem("renwuguanliFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetRenwuList(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private getBiaozhuyuan() {
    const params: any = {};
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuan = res;
    });
  }
  /**
   * @description 初始化
   */
  mounted() {
    // const filterData: any = JSON.parse(JSON.stringify(this.filters));
    this.filters.list_type = "text";
    this.filters["分类"] = "";
    this.filters["标注员"] = "";
    this.filters["分配状态"] = "";
    this.filters["标注状态"] = "";
    this.filters.page_count = 9;
    const d = localStorage.getItem("renwuguanliFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getBiaozhuyuan();
    this.getList();
  }
}
